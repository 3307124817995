// Navbar animation on hover
$(function () {
  const _navbar = $('.md-header');
  const _body = $('body');
  const _html = $('html');
  const _header = $('#top');
  const _header_navbar = $('#md-navbar-toggler');
  const _button_close = $('.hamburger.navbar-toggler');
  const menuItems = $('.navbar-nav-list > .nav-item.dropdown > .nav-link', _navbar);
  let windowWidth = $(window).width();

  const closeNav = () => {
    _navbar.removeClass('show');
    _body.removeClass('menu-open');
    _html.removeClass('menu-open');
    _button_close.removeClass('is-active').attr('aria-expanded', 'false');
    _header_navbar.attr('aria-expanded', false);
    _header_navbar.removeClass('show');
  };

  const toggleMenu = function (e) {
    if (windowWidth >= 992 && _header.hasClass('sticky') === false) {
      if (['mouseenter', 'focus'].includes(e.type)) {
        _navbar.addClass('show');
      } else {
        _navbar.removeClass('show');

      }
    }
  };

  function setSubmenuHeight() {
    const submenuItemHeights = $('#uq-submenu-wrapper > li > ul').map(function () {
      return $(this).height();
    }).get();

    const maxSubmenuItemsHeight = Math.max.apply(null, submenuItemHeights);

    _header.css('--md-submenu-height', `${ maxSubmenuItemsHeight }px`);
  }

  setSubmenuHeight();

  $(window).on('resize', function () {
    windowWidth = $(window).width();

    if (windowWidth >= 992) {
      setSubmenuHeight();
    }
  });

  $(window).on('click', function (e) {
    if ($(e.target).closest(_header).length === 0 && $(e.target).closest(_button_close).length === 0) {
      closeNav();
    }
  });

  menuItems.on('mouseenter focus click', function (e) {
    if ($(this).parent().hasClass('dropdown') && $(this).attr('href') === '#' && e.type === 'click') {
      e.preventDefault();
      e.stopPropagation();
    } else {
      toggleMenu(e);
    }
  });

  function setMenuHover() {
    const menuItemsLinks = $('#top .nav-link');

    menuItemsLinks.each(function () {
      const $navLink = $(this);

      if ($navLink.is('[href*="#"')) {
        $navLink.parent().removeClass('current-menu-item active');

        $navLink.click(function () {
          if (windowWidth >= 992) {
            const currentIndex = $navLink.parent().index(),
              parentElement = $navLink.closest('ul');
            parentElement.find('li').not(':eq(' + currentIndex + ')').removeClass('current-menu-item current-menu-ancestor');
            $navLink.parent().addClass('current-menu-item current-menu-ancestor');
          } else if (!$navLink.hasClass('dropdown-toggle')) {
            closeNav();
          }
        });
      }
    });
  }

  setMenuHover();

  _navbar.on('mouseleave blur', toggleMenu);
});
